import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const teacherAccess = () => {
  if (!store.getters.hasTeacherAccess) return "/404";
};
const advisorCanAddExercice = () => {
  if (!store.getters.hasAdvisorAccess || !store.getters.currentUser.advisorAddExercice) return "/404";
};

const chatAccess = () => {
  if (!store.getters.hasTeacherAccess || !store.getters.buildingConfigValue.teacherParentChat) return "/404";
};
const advisorAccess = () => {
  if (!store.getters.hasAdvisorAccess) return "/404";
};

const teacherOrAdvisorAccess = () => {
  if (!store.getters.hasTeacherAccess && !store.getters.hasAdvisorAccess)
    return "/404";
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/sghartoon",
        name: "sghartoon",
        component: () => import("@/views/common/sghartoon/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/sghartoon/video/:id",
        name: "sghartoon-video",
        component: () => import("@/views/common/sghartoon/video.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/teacher/availability",
        name: "availability-teacher",
        component: () => import("@/views/teacher/availability/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/chat-parents",
        name: "chat-teacher",
        component: () => import("@/views/teacher/chat/index.vue"),
        beforeEnter: chatAccess,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/common/notifications/index.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/teacher/meetings",
        name: "meetings-teacher",
        component: () => import("@/views/teacher/meetings/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/meetings",
        name: "meetings-advisor",
        component: () => import("@/views/advisor/meetings/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/actualites",
        name: "actualites",
        component: () => import("@/views/common/actualites/index.vue"),
      },
      {
        path: "/course",
        name: "teacher-myCourses",
        component: () => import("@/views/teacher/course/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/course/:id",
        name: "teacher-myCourseDeatils",
        component: () => import("@/views/teacher/course/coursDetails.vue"),
        beforeEnter: teacherAccess,
      },

      {
        path: "/course/:id/edit",
        name: "teacher-myCourseEdit",
        component: () => import("@/views/teacher/course/editCourse.vue"),
        beforeEnter: teacherOrAdvisorAccess,
      },
      {
        path: "/course/new",
        name: "teacher-myCourseNew",
        component: () => import("@/views/teacher/course/ajoutCours.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/course/advisor-new",
        name: "advisor-myCourseNew",
        component: () => import("@/views/advisor/coursesValidation/ajoutCourse/index.vue"),
        beforeEnter: advisorCanAddExercice,
      },
      {
        path: "/exercise",
        name: "teacher-myExercise",
        component: () => import("@/views/teacher/exercise/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/exercise/:id",
        name: "teacher-myExerciseDeatils",
        component: () => import("@/views/teacher/exercise/exerciceDetails.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/quiz/:id",
        name: "teacher-myQuizDeatils",
        component: () => import("@/views/teacher/exercise/quizDetails.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/exercise/:id/edit",
        name: "teacher-myExerciseEdit",
        component: () => import("@/views/teacher/exercise/editExercice.vue"),
        beforeEnter: teacherOrAdvisorAccess,
      },
      {
        path: "/quiz/:id/edit",
        name: "teacher-myQuizEdit",
        component: () => import("@/views/teacher/exercise/editQuiz.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/exercise/new",
        name: "teacher-myExerciseNew",
        component: () => import("@/views/teacher/exercise/ajoutExercice.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/exercise/advisor-new",
        name: "advisor-myExerciseNew",
        component: () => import("@/views/advisor/exercisesValidation/ajoutExercice/index.vue"),
        beforeEnter: advisorCanAddExercice,
      },
      {
        path: "/research",
        name: "advisor-myResearch",
        component: () => import("@/views/advisor/research/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/research/:id",
        name: "advisor-myResearchDeatils",
        component: () => import("@/views/advisor/research/researchDetails.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/punishments",
        name: "advisor-punishments",
        component: () => import("@/views/advisor/punishment/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/research/:id/edit",
        name: "advisor-myResearchEdit",
        component: () => import("@/views/advisor/research/editResearch.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/research/new",
        name: "advisor-myResearchNew",
        component: () => import("@/views/advisor/research/ajoutResearch.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/exercisesValidation",
        name: "advisor-exercisesValidation",
        component: () =>
          import("@/views/advisor/exercisesValidation/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/exercisesValidation/:id",
        name: "advisor-exercisesValidation-ExerciseDeatils",
        component: () =>
          import("@/views/advisor/exercisesValidation/exerciceDetails.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/quizzesValidation/:id",
        name: "advisor-exercisesValidation-QuizDeatils",
        component: () =>
          import("@/views/advisor/exercisesValidation/quizDetails.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/coursesValidation",
        name: "advisor-coursesValidation",
        component: () => import("@/views/advisor/coursesValidation/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/coursesValidation/:id",
        name: "advisor-coursesValidation-CourseDeatils",
        component: () =>
          import("@/views/advisor/coursesValidation/coursDetails.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/students",
        name: "teacher-myStudents",
        component: () => import("@/views/teacher/students/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/teachers",
        name: "advisor-myTeachers",
        component: () => import("@/views/advisor/teachers/index.vue"),
        beforeEnter: advisorAccess,
      },
      {
        path: "/teachers/:id",
        name: "advisor-teachersProfile",
        component: () => import("@/views/advisor/teachers/profile/index.vue"),
        beforeEnter: advisorAccess,
        children: [
          {
            path: "overview",
            name: "advisor-teacherOverview",
            component: () =>
              import("@/views/advisor/teachers/profile/overview/index.vue"),
          },
          {
            path: "timetable",
            name: "advisor-teacherTimetable",
            component: () =>
              import("@/views/advisor/teachers/profile/timetable/index.vue"),
          },
        ],
      },
      {
        path: "/teacher/research",
        name: "teacher-research",
        component: () => import("@/views/teacher/research/index.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/teacher/research/:id",
        name: "teacher-research-details",
        component: () => import("@/views/teacher/research/details.vue"),
        beforeEnter: teacherAccess,
      },
      {
        path: "/student/:id",
        name: "student",
        component: () => import("@/views/teacher/student/index.vue"),
        beforeEnter: teacherAccess,
        children: [
          {
            path: "overview",
            name: "teacher-studentOverview",
            component: () =>
              import("@/views/teacher/student/overview/index.vue"),
          },
          {
            path: "attendance",
            name: "teacher-studentAttendance",
            component: () =>
              import("@/views/teacher/student/attendance/index.vue"),
          },
          {
            path: "grades",
            name: "teacher-studentGrades",
            component: () => import("@/views/teacher/student/grades/index.vue"),
          },
          {
            path: "punishments",
            name: "teacher-studentPunishments",
            component: () =>
              import("@/views/teacher/student/Punishments/index.vue"),
          },
          {
            path: "submissions",
            name: "teacher-studentSubmissions",
            component: () =>
              import("@/views/teacher/student/submissions/index.vue"),
          },
          {
            path: "submissions/add",
            name: "teacher-studentAddExercise",
            component: () =>
              import("@/views/teacher/student/submissions/addExercise.vue"),
          },
        ],
      },
      {
        path: "/profile",
        redirect: "/profile/overview",
        name: "profile",
        component: () => import("@/views/common/profile/Account.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () => import("@/views/common/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () => import("@/views/common/profile/Settings.vue"),
          },
        ],
      },
      {
        path: "/classe/:id/",
        name: "classe",
        props: true,
        component: () => import("@/views/teacher/classe/index.vue"),
        beforeEnter: teacherAccess,
        children: [
          {
            path: "punitions",
            name: "classe-punitions",
            component: () =>
              import("@/costumComponents/classe/punishment/index.vue"),
          },
          {
            path: "eleves",
            name: "classe-eleves",
            component: () =>
              import("@/costumComponents/classe/eleves/index.vue"),
          },
          {
            path: "assiduite",
            name: "classe-assiduite",
            component: () =>
              import("@/costumComponents/classe/assiduite/index.vue"),
          },
          /* {
             path: "notes",
             name: "classe-notes",
             component: () =>
               import("@/costumComponents/classe/notes/index.vue"),
           },*/
          {
            path: "appreciationsPeda",
            name: "classe-appreciationsPeda",
            component: () =>
              import("@/costumComponents/classe/appreciations/appPeda.vue"),
          },
          {
            path: "appreciationPilote",
            name: "classe-appreciationPilote",
            component: () =>
              import(
                "@/costumComponents/classe/appreciations/appPilote.vue"
              ),
          },
          {
            path: "appreciationSpecific",
            name: "classe-appreciationSpecific",
            component: () =>
              import(
                "@/costumComponents/classe/appreciations/appSpec.vue"
              ),
          },
          {
            path: "mark/:type",
            name: "classe-mark",
            props: true,
            component: () =>
              import("@/costumComponents/classe/mark/index.vue"),
          },
          {
            path: "cours",
            name: "classe-courses",
            component: () =>
              import("@/costumComponents/classe/cours/index.vue"),
          },
          {
            path: "exercices",
            name: "classe-exercices",
            component: () =>
              import("@/costumComponents/classe/exercices/index.vue"),
          },
          {
            path: "ajoutCours",
            name: "classe-ajoutCours",
            component: () =>
              import("@/costumComponents/classe/cours/ajoutCours.vue"),
          },
          {
            path: "coursDetails/:cid",
            name: "classe-coursDetails",
            component: () =>
              import("@/costumComponents/classe/cours/coursDetails.vue"),
          },
          {
            path: "editCourse/:cid",
            name: "classe-editCourse",
            component: () =>
              import("@/costumComponents/classe/cours/editCourse.vue"),
          },
          {
            path: "ajoutExercice",
            name: "classe-ajoutExercice",
            component: () =>
              import("@/costumComponents/classe/exercices/ajoutExercice.vue"),
          },
          {
            path: "exerciceDetails/:hid",
            name: "classe-exerciceDetails",
            component: () =>
              import("@/costumComponents/classe/exercices/exerciceDetails.vue"),
          },
          {
            path: "quizDetails/:hid",
            name: "classe-quizDetails",
            component: () =>
              import("@/costumComponents/classe/exercices/quizDetails.vue"),
          },
          {
            path: "editExercice/:hid",
            name: "classe-editExercice",
            component: () =>
              import("@/costumComponents/classe/exercices/editExercice.vue"),
          },
          {
            path: "editQuiz/:hid",
            name: "classe-editQuiz",
            component: () =>
              import("@/costumComponents/classe/exercices/editQuiz.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/common/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-forgot",
        name: "password-forgot",
        component: () =>
          import("@/views/common/authentication/basic-flow/PasswordForgot.vue"),
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        component: () =>
          import("@/views/common/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/common/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/common/authentication/Error500.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import("@/views/common/authentication/basic-flow/test.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (
    !["sign-in", "password-reset", "password-forgot"].includes(String(to.name))
  )
    store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
