import { createI18n } from "vue-i18n";

const messages = {
  en: {
    swalMessages: {
      somethingWrong: "Something went wrong",
      requiredFields: "Please fill all the required fields"
    },
    readAndAccept: "I have read and accept the",
    termsAndConditions: "Terms and Conditions",
    advisorLabel: "Advisor",
    teacherLabel: "Teacher",
    all: "All",
    availability: {
      saved: "Your availability has been modified successfully!",
      indicateAvailability: "Please indicate your availability for {day}.",
      title: "Availability",
      titleTeacher: "Teacher's availability",
      available: "I am available",
      notAvailable: "I am not available",
      note: "Please click on the time slots you are available so that the administration can establish the schedules more easily.",
    },
    sghartoon: {
      courseList: "Training list",
      courseDetails: "Course details",
      watch: "Watch",
      videos: "Videos",
    },
    calendar: {
      month: "Month",
      week: "Week",
      day: "Day",
      addEvent: "Add event",
      addEventTitle: "Add a new event",
      duration: "Duration",
      durationRequired: "Duration is required field",
      exam: "Exam",
      party: "Party",
      excursion: "Excursion",
      meeting: "Meeting",
      other: "Other",
      eventType: "Event type",
      color: "Color",
      typeRequired: "Event type is required field",
      editEventTitle: "Edit event",
      editEvent: "Edit event",
      editedEvent: "The event has been edited successfully",
      deletedEvent: "The event has been deleted successfully",
      addedEvent: "The event has been added successfully",
      tous: "All",
      teachers: "Teachers",
      students: "Students",
      administration: "Administration",
      parents: "Parents",
      title: "Title",
      target: "Targets",
      startDate: "Start date",
      endDate: "End date",
    },
    chat: {
      ROOMS_EMPTY: "No rooms",
      ROOM_EMPTY: "No room selected",
      NEW_MESSAGES: "New Messages",
      MESSAGE_DELETED: "This message was deleted",
      MESSAGES_EMPTY: "No messages",
      CONVERSATION_STARTED: "Conversation started on:",
      TYPE_MESSAGE: "Type message",
      SEARCH: "Search",
      IS_ONLINE: "is online",
      LAST_SEEN: "last seen ",
      addParent: "Add parent",
      chatWithParants: "Chat with parents",
      startNewConversationWithParent: "Create new conversation with parent",
    },
    downloadGuide: "Download EdTeacher User Guide",
    notification: {
      notifications: "Notifications",
      newNotification: "New notification",
      viewAll: "View all",
    },
    appreciation: {
      yourAppreciation: "Write an appreciation",
      saved: "Your appreciation has been saved successfully!",
      delete: "Your appreciation has been deleted successfully!",
      appreciation: "Appreciation",
      appreciations: "Appreciations",
      moyenne: "Average of module",
      teacherAppreciations: "Teacher appreciations",
      confirmCancel: "Are you sure you want to cancel ?",
      unsavedchanges: "Unsaved changes!",
      unsavedchangesContent:
        "Do you really want to leave? You have unsaved changes!",

    },
    resetpassword: {
      linkExpired: "The link you visited expired",
      linkExpiredDesc: "The link you visited expired. Please try again !",
      passwordChanged: "Your password has been changed successfully",
      close: "Close",
      forgotPassword: "Forgot Password",
      forgotPasswordText:
        "Enter your email to reset your password. If you do not have an email address, please contact the administrator",
      resetPasswordReqested: "Password reset requested",
      resetPasswordReqestedDesc:
        "We have sent you an email, please check it to reset your password!",
      cancel: "Cancel",
      Submit: "Submit",
      emailNotExist: "This email does not exist",
    },
    meeting: {
      noMeetings: "There is no events",
      myMeetings: "Calendar",
      meetings: "Calendar",
      calendar: "Calendar",
      addMeeting: "Add a meeting",
      topic: "Topic",
      addNewMeeting: "Add a new Meeting",
      editMeeting: "Edit Meeting",
      duration: "Duration (Hours)",
      date: "Date",
      attendances: "Attendances",
      rejected: "Can't attend",
      confirmed: "Will attend",
      createdBy: "Created by",
      status: "Status",
      submit: "Submit",

      meetingAdded: "Meeting has been successfully added!",
      meetingEdited: "Meeting has been successfully edited!",
      meetingDeleted: "Meeting has been successfully deleted!",
      confirmDelete: "Are you sure you want to delete this meeting ?",
      submittedAttendance: "Your attendance has been successfully submitted!",
      meetingError:
        "Sorry, looks like there are some errors detected, please try again.",
    },
    teacher: {
      myTeachers: "My teachers",
      findTeacher: "Find teacher",
      numberTeacher: "There are {nb} teachers",
      teacherList: "Teachers List",
      teacherProfile: "Teacher profile",
      grade: "Grade",
    },
    stats: {
      validationActivity: "Activity Validation",
      studentGradePerformance: "Grades Performance",
      studentGradePerformanceThisTrimester:
        "Grades performance in this trimester",
      maxMark: "Max mark",
      minMark: "Min mark",
      studentMark: "Student mark",
      thisWeekStats: "This Week stats",
      numberHoursInWeek: "Number of hours of the week",
      numberHoursToday: "Number of hours today",
      numberHoursTomorrow: "Number of hours tomorrow",
      averageHoursPerDay: "Average working hours per day",
      todayAndTomorrow: "Today and tomorrow",
      nbHours: "{nb} hours",

      attendanceStats: "Attendance Stats",
      classroomAttendanceThisMonth: "Classroom attendance in this month",
      numberAbsentsInYear: "Number of absences in this year",
      numberDelaysInYear: "Number of delays in this year",

      studentsStats: "Students stats",
      numberOfGirls: "Number of girls",
      numberOfBoys: "Number of boys",

      activity: "Activity",
      byMonth: "By month",
      bySubject: "By subject",
    },
    student: {
      studentProfile: "Student profile",
      findSubject: "Find subject",

      myStudents: "My students",
      studentList: "Students list",
      numberStudent: "There are {nb} students",
      findStudent: "Find student",
      photo: "Photo",
      fullname: "Full name",
      gender: "Gender",
      birthdate: "Date of birth",
      address: "Address",
      parentSheet: "Parent sheet",
      female: "Girl",
      male: "Boy",
      details: "Details",
      fatherSection: "Father section",
      motherSection: "Mother section",
      phone: "Phone",
      parentMoreInfo: "For more information contact the administration.",
      close: "Close",
    },
    comment: {
      confirmDelete: "Are you sure you want to delete this comment ?",
      deleted: "You comment has been deleted successfully!",
      comments: "Comments",
      reply: "Reply",
    },
    course: {
      noTeachersFound: "There's no teacher found for the selected subject",
      filesTooLarge: "The files you sent are too large!",
      maxFilesUploadExceed:
        "You have exceeded the maximum number of files allowed to be uploaded. You cannot upload more than 15 files.",
      photos: "Photos",
      others: "Others",
      researchSubmitted: "Your research has been submitted successfully!",
      details: "Details",
      schoolarYear: "Scholar Year",
      uploadFiles: "Upload files",
      selectSubmissionMethod: "Select submission method",
      writeAnswer: "Write an answer",
      homeworkSubmitted: "Your homework has been submitted!",
      answer: "Answer",
      submit: "Submit",
      invalidSubmission: "Something went wrong",
      invalidSubmissionText: "You must submit files or write an answer",

      specificTeacher: "Specific teacher",
      teachers: "Teachers",
      chooseTeachers: "Choose the teachers",
      teacherSubmissions: "Teacher submissions",

      levels: "Levels",
      allLevels: "All levels",
      chooseLevels: "Choose the levels",

      myResearches: "My researches",
      research: "Research",
      researches: "Researches",
      nbrResearches: "{nb} researches",
      nbResearches: "{nb} researches",
      addResearch: "Add a new research",
      editResearch: "Edit a research",
      infoResearch: "Research details",
      confirmDeleteResearch: "Are you sure you want to delete this research?",
      deleteResearchWarn: "You cannot go back!",
      deletedResearchInfo: "Your research has been deleted successfully !",
      editedResearchInfo: "The research has been modified successfully!",
      addedResearch: "The research has been successfully added!",

      addedResearchesInMonth: "{nb} researches added in the this month",
      totalResearches: "Total researches",

      validatedExercisesInMonth: "{nb} exercises reviewed in the this month",
      validatedCoursesInMonth: "{nb} courses reviewed in the this month",
      validatedExercises: "Exercises review",
      validatedCourses: "Courses review",
      validatedExercisesBySubject: "Subjects review",
      validatedCoursesBySubject: "Subjects review",

      validatedFilter: "Validated",
      notValidatedFilter: "Not validated",
      rejectedFilter: "Rejected",
      reason: "Reason",
      exerciseReview: "Exercise review",
      courseReview: "Course review",
      review: "Review",

      allTeachers: "All Teachers",
      exercisesValidation: "Validation of exercises",
      coursesValidation: "Validation of courses",
      validate: "Validate",
      confirmValidationHomework:
        "Are you sure you want to validate this exercise?",
      confirmValidationCourse: "Are you sure you want to validate this course?",
      acceptValidate: "Yes, validate it!",
      validated: "Validated !",
      validatedHomeworkInfo: "The exercise has been validated successfully !",
      validatedCourseInfo: "The course has been validated successfully !",

      confirmRejectHomework: "Are you sure you want to reject this exercise?",
      confirmRejectCourse: "Are you sure you want to reject this course?",
      acceptReject: "Yes, reject it!",
      rejected: "Rejected !",
      rejectedHomeworkInfo: "The exercise has been rejected successfully !",
      rejectedCourseInfo: "The course has been rejected successfully !",

      specificExercises: "Specific exercises",
      nbtotalExercises: "Total exercises : {nb}",
      finishedExercises: "Finished exercises : {nb}",
      notFinishedExercises: "Not finished exercises : {nb}",
      submissionsState: "Submissions state",
      submissionsStateTitle: "Student submissions in this trimester",
      incompleted: "Not finished",
      lateFinish: "Late finished",
      completeInTime: "Completed in time",
      assign: "Assign",
      completed: "Completed",
      missing: "Missing",
      nbCourses: "{nb} courses",
      nbExercises: "{nb} exercises",
      totalCourses: "Total courses",
      totalExercises: "Total exercises",
      added: "added",
      addedCoursesInMonth: "{nb} courses added in the this month",
      addedExercisesInMonth: "{nb} exercises added in the this month",
      addedCoursesInTrimester: "{nb} courses added in the this trimester",
      addedExercisesInTrimester: "{nb} exercises added in the this trimester",
      addedCoursesInYear: "{nb} courses added in the this year",
      addedExercisesInYear: "{nb} exercises added in the this year",
      state: "State",
      classrooms: "Classrooms",
      chooseClassrooms: "Choose the classrooms",
      requiredClassroom: "The classrooms field is required",
      allClassrooms: "All classrooms",
      myCourses: "My courses",
      myExercises: "My exercises",
      specificFor: "Specific for",
      allStudent: "All students",
      specificStudent: "Specific students",
      students: "Students",
      chooseStudents: "Choose the students",
      requiredStudents: "Students is a required field",
      subjects: "Subjects",
      subject: "Subject",
      courses: "Courses",
      course: "Course",
      exercises: "Exercises",
      exercise: "Exercise",
      nbrCourses: "There are {nb}  courses",
      nbrExercises: "There are {nb} exercises",
      title: "Title",
      lastComment: "Last comment by",
      teacher: "Teacher",
      publicationDate: "Publication date",
      search: "Search",
      deadline: "Deadline",
      module: "Module",
      description: "Description",
      privateMessage: "Message privé",
      resources: "Resources",
      noResources: "There no resources",
      homework: "Homework",
      marked: "Marked",
      notMarked: "Not marked",
      yes: "Yes",
      no: "No",
      drobFiles: "Drop files here",
      fileSize: "Files must be of size less than 50 MB",
      reply: "Reply",
      addExercise: "Add a new exercise",
      editExercise: "Edit exercise",
      add: "Add",
      addedExercise: "The exercise has been successfully added!",
      cancel: "Cancel",
      chooseSubject: "Choose a subject",
      chooseDate: "Choose date",
      chooseTitle: "Choose a title",
      chooseModule: "Choose a module",
      confirmDeleteExercise: "Are you sure you want to delete this exercise?",
      deleteExerciseWarn: "You cannot go back!",
      acceptDelete: "Yes, delete it!",
      deleted: "Deleted!",
      deletedExerciseInfo: "Your exercise has been deleted successfully !",
      editedExerciseInfo: "The exercise has been modified successfully!",
      okay: "OK!",
      edit: "Edit",
      infoExercise: "Exercise details",
      delete: "Delete",
      addCourse: "Add a new course",
      editCourse: "Edit course",
      addedCourse: "The course has been successfully added!",
      infoCourse: "Course details",
      confirmDeleteCourse: "Are you sure you want to delete this course?",
      deleteCourseWarn: "You cannot go back!",
      deletedCourseInfo: "Your course has been deleted successfully !",
      editedCourseInfo: "The course has been modified successfully!",
      requiredSubject: "Subject is a required field",
      requiredModule: "Module is a required field",
      requiredTitle: "Title is a required field",

      requiredLevels: "Levels is required field",
      requiredDesc: "Description is a required field",
      requiredTopic: "Topic is a required field",
      requiredDate: "Date is a required field",
      requiredTeachers: "Teachers is a required field",
      durationRule: "Duration should be more then 1 hour",

      errorText: "Something is wrong",
      tryAgain: "Please try again !",
      studentSubmissions: "Student submissions",
      submissions: "Submissions",
      submittedAt: "Submitted at",
      notNoted: "Not noted",
      submissionDate: "Submission date",
      submissionMethod: "Submission method",
      files: "Files",
      note: "Note",
      saveNote: "Save mark",
      noteRule: "The mark should be between 0 and 20",
      submitted: "Submitted",
      allTrimesters: "All trimesters",
      studentSubmission: "Student submission",
      updatedSubmissionMark: "Submission has been updated successfully!",
      allSubjects: "All subjects",

      quiz: "Quiz",
      question: "Question",
      answers: "Answers",
      correctAnswers: "Correct answers",
      wrongAnswers: "Wrong answers",
      requiredQuestion: "Question is a required field",
      addCorrectAnswer: "Add correct answer",
      addWrongAnswer: "Add wrong answer",
      pleaseFillOrDeleteAnswers: "Please fill the empty questions or answers",
      addAnswer: "Add answer",
      removeQuestion: "Remove question",
      addQuestion: "Add question",
      isCorrect: "Correct",
    },

    punishments: {
      notFound: "No punishment is found",
      punishments: "Punishments",
      punishment: "Punishment",
      student: "Student",
      students: "Students",
      time: "Time",
      reason: "Reason",
      description: "Description",
      valid: "Validated",
      notValidated: "En attente",
      rejected: "Rejected",

      actions: "Actions",
      edit: "Edit",
      delete: "Delete",
      add: "Add",
      punishmentSearch: "Search for punishments",
      confirmDelete: "Are you sure?",
      yes: "Yes",
      cancel: "Cancel",
      punishmentType: "Punishment type",
      punishmentEdit: "Modify a punishment",
      punishmentAdd: "Add a new punishment",
      close: "Close",
      times: "Times",
      chooseStudent: "Choose a student",
      choosePunishment: "Choose a punishment",
      chooseDate: "Choose date",
      writeReason: "Write a reason",
      writeDescription: "Write a description",
      added: "Added successfully!",
      edited: "Edited successfully!",
      deleted: "Deleted!",
    },

    notes: {
      areYouSureToDeleteMark: "Are you sure to delete the mark?",
      invalidMarks: "Please ensure your marks fall within the range of 0 to 20",
      notCorrectMarks:
        "Please ensure that your marks have been entered correctly",
      missedSubsubjects:
        "It appears that not all of ({name})'s marks have been completed. Please fill in any missing marks.",
      notes: "Grades",
      note: "Note",
      subject: "Subject",
      trimester: "Trimester",
      edit: "Edit",
      confirm: "Confirm",
      cancel: "Cancel",
      student: "Student",
      updated: "Note updated!",
      noEmpty: "Note must not be empty!",
      noInvalid: "The note must be between 0 and 20!",

      chooseSubject: "Choose a subject",
      chooseTrimester: "Choose a trimester",
      trimester1: "Trimester 1",
      trimester2: "Trimester 2",
      trimester3: "Trimester 3",
      gradeState: "Grades state",
      stateTitle: "Student grades in this trimester",
      lessThan: "Less than {nb}",
      moreThan: "More than {nb}",
      between: "Between",
      aAndB: "{a} and {b}",
      save: "Save",
      saveAll: "Save all",
      savedAll: "All the marks are saved successfully!",
      noteRemoved: "The grade has been removed successfully",
      medicalNote: "Medical Note",
      unmarkedStudents: "Missing Grades",
      followingStudentsUnmarked: "All grades must be entered for the following students",
      generateAnyway: "Save Anyway",
      missingSubSubjects: "Missing subsubjects",
      confirmation: "Validate Grades",
      confirmValidationWarning: "Once validated, these grades cannot be modified.",
      impossibleToModify: "Impossible to modify",
      alreadyValidated: "The grades for this subject have already been validated, you can neither modify nor delete them",
    },

    attendance: {
      attendanceState: "Attendance Sate",
      titleState: "Student attendance in this month",
      sessions: "Sessions on {date}",
      chooseSession: "Choose a session",
      delayInMonth: "Delay in this month",
      absenceInMonth: "Absence in this month",
      presenceInMonth: "Presence in this month",
      absent: "Absent",
      delay: "Delay",
      present: "Present",
      absents: "Absents",
      delays: "Delays",
      updated: "Attendance has been updated successfully!",
      inThisYear: "In this year",
      totalAbsents: "Total absents : {nb}",
      totalDelays: "Total delays : {nb}",
    },

    classInfo: {
      numberStudent: "There are {nb} students",
      studentList: "Student list",
      attendance: "Attendance",
      notes: "Grades",
      punishments: "Punishments",
      courses: "Courses",
      exercises: "Exercises",
      girls: "Girls",
      boys: "Boys",
      register: "Register",
      submissions: "Submissions",
      appreciations: "Appreciations",
      appreciationPeda: "Pedagogic appreciations",
      appreciationS: "Pilote appreciations",
      appreciationVS: "Specific appreciations",
      markSpecific: "Specific marks ",
      markPeda: "Pedagogic marks",
      markPilote: "Pilote marks",
    },

    classList: {
      listYourClasses: "The list of your classes",
      numberClasses: "{nb} classes",
      class: "Class",
      subject: "Subject",
      action: "Action",
      counselor: "Counselor",
      findStudent: "Find student",
      notes: "Grades",
    },

    timetable: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      sat: "Sat",
      lesson: "lesson",
      skip: "skip",
      cabinet: "Room",
      court: "court",
      break: "Break",
      classRoom: "Classroom",
      print: "Print",
      noData: "No data",
    },
    feedback: {
      send: "Send",
      feedback: "Feedback",
      paragraphe1: "Mrs. and Mr , ",
      paragraphe2: "Please write your feedback. or report for a problem",
      paragraphe3: "",
      paragraphe4: " ",
      placeholder: "Enter something ...",
      images: "Images",
      audio: "Audio",
      text: "Message",
    },
    editProfile: {
      photo: "Photo",
      father: "Father Name ",
      mother: "Mother Name",
      motherPhone: "Contact Phone ",
      fatherPhone: "Contact Phone",
      save: "Save Changes",
      discard: "Discard",
      wait: "Please wait... ",
      edit: "Edit Profile",
      allowedFiles: "Allowed file types: png, jpg, jpeg.",
      motherJob: " Mother job",
      fatherJob: "Father job ",
      adress: "adress",

      general: "General",
      settings: "Settings",
      fullname: "Full Name",
      firstname: "First name",
      lastname: "Last name",
      birthday: "Birthday",
      telephoneNumber: "Phone number",
      gender: "Gender",
      address: "Address",
      editProfile: "Edit profile",
      profileDetails: "Profile details",
      profileDetailsUpdated:
        "Your Profile details has been updated successfully!",
      boy: "Boy",
      girl: "Girl",
      verified: "Verified",
      allowedAvatarFiles: "Allowed file types: png, jpg, jpeg.",
      cancel: "Cancel",
      saveChanges: "Save changes",
      securitySettings: "Security Settings",
      currentPasswordIncorrect:
        "The current password is incorrect, please try again",
      emailAddress: "Email address",
      changeEmail: "Change email",
      updateEmail: "Update email",
      enterNewEmail: "Enter a new email address",
      confirmPassword: "Confirm the password",
      password: "Password",
      resetPassword: "Reset password",
      passwordRule:
        "Password must be at least 8 characters long and contain symbols",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm the new password",
      updatePassword: "Update password",
      profileCompletion: "Profile Completion",
      accountSettings: "Account Settings",
      myProfile: "My Profile",
      student: "Student",
      updatedPhoto: "Photo updated!",
      updatePhotoError: "Something wrong",
      requiredFirstname: "First name is a required field",
      requiredLastname: "Last name is a required field",
      requiredPhone: "Phone number is a required field",
      requiredAddress: "Address is a required field",
      requiredCurrentPassword: "Current password is a required field",
      requiredNewPassword: "New Password is a required field",
      requiredConfirmPassword: "Password Confirmation is a required field",
      passwordConfirmationCondition:
        "Password Confirmation must be at least 4 characters",
      passwordNewCondition: "New Password must be at least 4 characters",
      passwordMustMatch: "Password must match",
      passwordUpdated: "Your password has been updated successfully",
      emailUpdated: "Your email has been updated successfully",
      speciality: "Speciality",
      cin: "ID card",
      civilstatus: "Civil status",
      requiredCivilstatus: "Civil status is a required field",
      requiredCin: "ID card is a required field",
      requiredSpeciality: "Speciality is a required field",
      invalidCin:
        "The ID card number must start with 0 or 1 and contain 8 digits",
      invalidPhone: "The phone number must contain 8 digits",
      invalidEmail: "The email must be a valid",
      requiredEmail: "The email is a required field",
      requiredSchool: "The school name is a required field",
      unavailableEmail: "Email is not available!",
    },
    dashboard: {
      timetable: "Timetable",
      phoneNumber: "Phone number",
      headMaster: "Headmaster",
      responsibleLevels: "Responsible levels",
      responsibleSubjects: "Responsible subjects",
      year_1: "Preparatory",
      year_2: "Preschool",
      year1: "1st year",
      year2: "2nd year",
      year3: "3rd year",
      year4: "4th year",
      year5: "5th year",
      year6: "6th year",
      year7: "7th year",
      year8: "8th year",
      year9: "9th year",
      dashboard: "Dashboard",
      comments: "Comments",
      likes: "Likes",
      settings: "Settings",
      consultpayment: "Consult Payment",
      mychild: "My child",
      actualites: "News",
      details: "Details",
      canteen: "Canteen",
      payment: "Payment",
      report: "School Reports",
      schedule: "Schedule",
      avertissements: "Avertissements",
      reclamations: "Reclamations",
      profile: "My Profile",
      language: "Language",
      signout: "Sign Out",
      arabic: "Arabic",
      english: "English",
      french: "French",
      changeSchoolarYear: "Change schoolar year",
      thisWeekMeeting: "Réunions de cette semaine",
    },
    report: {
      informations:
        "The administration will add the transcripts at the end of each semester so that you can monitor your child's level.",
      update: "Last update :",
      premsem: "1st semester",
      deuxsem: "2nd semester",
      troisem: "3rd semester",
      download: "Download",
      unavailable: "Unavailable school report !",
    },
    canteen: {
      informations:
        "The canteen is often the lunchtime solution. A new adventure for your children who, for the most part, want more after tasting it! We don’t laugh with children's food! The school offers them balanced and varied menus and, even if it cannot ensure their nutritional balance on its own, the midday meals have the merit, in any case, of meeting their needs. The administration will add the dishes of each day.",
      informations2: "The administration will add the dishes of each day.",
      choosedate: "Choose a date :",
      unavailable: "Unavailable Data !",
      forthisdate: "For this date :",
      schooloffers: "Our school offers ",
    },
    schedule: {
      informations:
        "The administration will add your child's schedule for each semester.",
      unavailable: "Unavailable schedule !",
    },
    avertissement: {
      teacher: "Teacher",
      subject: "Subject",
      reason: "Reason",
      notes: "Notes",
      importantInformations: "Important informations",
      informations:
        "The sanctions contribute not to exclude from the class but to integrate into it: they recognize the pupil's responsibility for his acts and, at the same time, allow him to return to the group from which he has excluded himself. .Any warning is validated by our administration. ",
      informations2: "For more information, please contact the administration.",
      totalnumber: "Total Number",
      unavailable: "There are no avertissements !",
    },
    news: {
      reaction: "Your Reactions",
      totalPosts: "Number of Posts",
      newPosts: "New Posts",
      noNews: "The administration has not published any news yet",
    },
    payment: {
      monthly: "Monthly",
      scolarityPayment: "Scolarity Payment",
      activityPayment: "Activity Payment",
      finalStatus: "Final Status",
      month: "Month",
      state: "State",
      activityName: "Activity Name",
      fees: "Fees",
      progress: "Progress of paiement",
      singlePayment: "Single Payment",
      monthlyPayment: "Monthly Payment",
      monthlyFees: "Monthly Fees",
      afterReduction: "After reduction",
      inscriptionFees: "Inscription Fees",
      inscriptionReduction: "Inscription Reduction",
      monthlyReduction: "Monthly Reduction",
      inscription: "Inscription",
    },
    settings: {
      options: "Options",
      child: "Child",
      year: "School Year",
      apply: "Apply",
      reset: "Reset",
      chooseChild: "Choose your child",
      chooseYear: "Choose the school year",
    },
    pleaseWait: "Please wait...",

    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    arabic: "Arabic",
    english: "English",
    french: "French",
  },

  fr: {
    swalMessages: {
      somethingWrong: "Quelque chose s'est mal passé",
      requiredFields: "Veuillez remplir tous les champs obligatoires"
    },
    readAndAccept: "j'ai lu et j'accepte les",
    termsAndConditions: "Termes et Conditions",
    advisorLabel: "Conseiller",
    teacherLabel: "Enseignant",
    all: "Tous",
    availability: {
      saved: "Votre disponibilité a été enregistrée avec succès",
      indicateAvailability: "Veuillez indiquer votre disponibilité pour {day}.",
      title: "Disponibilité",
      titleTeacher: "Disponibilité de l'enseignant",
      available: "Je suis disponible",
      notAvailable: "Je ne suis pas disponible",
      note: "Veuillez cliquer sur les créneaux horaires auxquels vous êtes disponibles afin que l'administration puisse établir les emplois du temps plus facilement.",
    },
    sghartoon: {
      courseList: "Liste de formation",
      courseDetails: "Détails de la formation",
      watch: "Regarder",
      videos: "Vidéos",
    },
    calendar: {
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      addEvent: "Ajouter un événement",
      addEventTitle: "Ajouter un nouvel événement",
      duration: "Durée",
      durationRequired: "La durée est requise",
      exam: "Examen",
      party: "Fête",
      excursion: "Excursion",
      meeting: "Réunion",
      other: "Autre",
      eventType: "Type d'événement",
      color: "Couleur",
      typeRequired: "Le type d'événement est requis",
      editEventTitle: "Modifier l'événement",
      editEvent: "Modifier l'événement",
      editedEvent: "L'événement a été modifié avec succès",
      deletedEvent: "L'événement a été supprimé avec succès",
      addedEvent: "L'événement a été ajouté avec succès",
      tous: "Tous",
      teachers: "Enseignants",
      students: "Elèves",
      administration: "Administration",
      parents: "Parents",
      title: "Titre",
      target: "Cibles",
      startDate: "Date de début",
      endDate: "Date de fin",
    },
    chat: {
      ROOMS_EMPTY: "Aucune conversation",
      ROOM_EMPTY: "Aucune conversation sélectionnée",
      NEW_MESSAGES: "Nouveaux messages",
      MESSAGE_DELETED: "Ce message a été supprimé",
      MESSAGES_EMPTY: "Aucun message",
      CONVERSATION_STARTED: "La conversation a commencée le :",
      TYPE_MESSAGE: "Tapez votre message",
      SEARCH: "Rechercher",
      IS_ONLINE: "est en ligne",

      parentDetails: "Détails du parent",
      startNewConversationWithParent:
        "Créer nouvelle conversation avec un parent",
      addParent: "Ajouter un parent",
      chatWithParents: "Discuter avec les parents",
    },
    downloadGuide: "Télécharger le guide EdTeacher",
    notification: {
      notifications: "Notifications",
      newNotification: "Nouvelle notification",
      viewAll: "Voir tout",
    },
    appreciation: {
      yourAppreciation: "Une appréciation",
      saved: "Votre appréciation a été enregistrée avec succès",
      delete: "Votre appréciation a été supprimée avec succès !",
      appreciation: "Appréciations",
      appreciations: "Appréciations",
      moyenne: "Moyenne du module",
      teacherAppreciations: "Appréciations des enseignants",
      confirmCancel: "Êtes-vous sûr de vouloir annuler ?",
      unsavedchanges: "Modifications non enregistrées !",
      unsavedchangesContent:
        "Voulez-vous vraiment partir ? Vous avez des changements non enregistrés !",
    },
    resetpassword: {
      linkExpired: "Le lien que vous avez visité a expiré",
      linkExpiredDesc:
        "Le lien que vous avez visité a expiré. Veuillez réessayez.",
      passwordChanged: "Votre mot de passe a été changé avec succès",
      close: "Fermer",
      forgotPassword: "Mot de passe oublié",
      forgotPasswordText:
        "Entrez votre email pour réinitialiser votre mot de passe. Si vous n'avez pas d'email, veuillez contacter l'administrateur",
      resetPasswordReqested: "Réinitialisation du mot de passe demandée",
      resetPasswordReqestedDesc:
        "Nous vous avons envoyé un email, veuillez le vérifier pour réinitialiser votre mot de passe !",
      cancel: "Annuler",
      submit: "Envoyer",
      emailNotExist: "Cette adresse email n'existe pas!",
    },
    meeting: {
      noMeetings: "Il n'y a pas de réunions",
      myMeetings: "Mes Réunions",
      meetings: "Réunions",
      calendar: "Calendrier",
      addMeeting: "Ajouter une réunion",
      topic: "Sujet",
      addNewMeeting: "Ajouter une nouvelle réunion",
      editMeeting: "Modifier la réunion",
      duration: "Durée (Heures)",
      date: "Date",
      attendances: "Présences",
      rejected: "Ne peut pas assister",
      confirmed: "Sera présent",
      createdBy: "Créé par",
      status: "Statut",
      submit: "Enregistrer",

      meetingAdded: "La réunion a été ajouté avec succès !",
      meetingEdited: "La réunion a été modifié avec succès !",
      meetingDeleted: "Votre réunion a été supprimé avec succès !",
      confirmDelete: "Voulez-vous vraiment supprimer cette réunion ?",
      submittedAttendance: "Votre participation a été soumise avec succès !",
      meetingError:
        "Désolé, il semble qu'il y a des erreurs détectées, veuillez réessayer.",
    },
    teacher: {
      myTeachers: "Mes enseignants",
      findTeacher: "Chercher",
      numberTeacher: "Il y a {nb} enseignants",
      teacherList: "Liste des enseignants",
      teacherProfile: "Profil de l'enseignant",
      grade: "Note",
    },
    stats: {
      validationActivity: "Validation Activités",

      studentGradePerformance: "Performances des notes",
      studentGradePerformanceThisTrimester:
        "Performance des notes de ce trimestre",
      maxMark: "Note maximale",
      minMark: "Note la plus basse",
      studentMark: "Note de l'élevé",
      thisWeekStats: "Statistiques de cette semaine",
      numberHoursInWeek: "Nombre d'heures de la semaine",
      numberHoursToday: "Nombre d'heures aujourd'hui",
      numberHoursTomorrow: "Nombre d'heures demain",
      averageHoursPerDay: "Heures de travail moyennes par jour",
      todayAndTomorrow: "Aujourd'hui et demain",
      nbHours: "{nb} heures",

      attendanceStats: "Statistiques de l'assiduité",
      classroomAttendanceThisMonth: "Assiduité de la classe ce mois-ci",
      numberAbsentsInYear: "Nombre d'absences cette année",
      numberDelaysInYear: "Nombre de retards cette année",

      studentsStats: "Statistiques des étudiants",
      numberOfGirls: "Nombre des filles",
      numberOfBoys: "Nombre des garçons",

      activity: "Activité",
      byMonth: "Par mois",
      bySubject: "Par matière",
    },
    student: {
      studentProfile: "Profil étudiant",
      findSubject: "Recherche d'une matière",
      myStudents: "Mes éléves",
      studentList: "Liste des étudiants",
      numberStudent: "Il y a {nb} étudiants",
      findStudent: "Chercher",
      photo: "Photo",
      fullname: "Nom et prénom",
      gender: "Sexe",
      birthdate: "Date de naissance",
      address: "Adresse",
      parentSheet: "Fiche parent",
      female: "Fille",
      male: "Garçon",
      details: "Détails",
      fatherSection: "Section père",
      motherSection: "Section mère",
      phone: "Téléphone",
      parentMoreInfo: "Pour plus d'informations contacter l'administration.",
      close: "Fermer",
    },
    comment: {
      confirmDelete: "Êtes-vous sûr de vouloir supprimer ce commentaire?",
      deleted: "Votre commentaire a été supprimé avec succès",
      comments: "Commentaires",
      reply: "Réponse",
    },
    course: {
      noTeachersFound: "Aucun professeur n'a été trouvé pour la matière sélectionnée",
      filesTooLarge:
        "Les fichiers que vous avez envoyés sont trop volumineux !",
      maxFilesUploadExceed:
        "Vous avez dépassé le nombre maximum de fichiers autorisés à être téléchargés. Vous ne pouvez pas télécharger plus de 15 fichiers.",
      others: "Autres",
      photos: "Photos",

      researchSubmitted: "Votre recherche a été soumise avec succès!",

      details: "Détails",
      schoolarYear: "Année scolaire",
      uploadFiles: "Choisir des fichiers",
      selectSubmissionMethod: "Sélectionnez la méthode",
      writeAnswer: "Rédigez une réponse",
      homeworkSubmitted: "Vos devoirs ont été remis",
      answer: "Réponse",
      submit: "Envoyer",
      invalidSubmission: "Quelque chose s'est mal passé!",
      invalidSubmissionText:
        "Vous devez soumettre des fichiers ou écrire la réponse!",
      specificTeacher: "Enseignant spécifique",
      teachers: "Enseignants",
      chooseTeachers: "Choisissez les enseignants",
      teacherSubmissions: "Soumissions des enseignants",
      levels: "Niveaux",
      allLevels: "Tous les niveaux",
      chooseLevels: "Choisissez les niveaux",

      myResearches: "Mes recherches",
      research: "Recherche",
      researches: "Recherches",
      nbrResearches: "{nb} recherches",
      nbResearches: "{nb} recherches",
      addResearch: "Ajouter une nouvelle recherche",
      editResearch: "Modifier une recherche",
      infoResearch: "Détails de la recherche",
      confirmDeleteResearch: "Voulez-vous vraiment supprimer cette recherche ?",
      deleteResearchWarn: "Vous ne pouvez pas revenir en arrière!",
      deletedResearchInfo: "Votre recherche a été supprimé avec succès!",
      editedResearchInfo: "La recherche a été modifié avec succès!",
      addedResearch: "La recherche a été ajouté avec succès!",

      addedResearchesInMonth: "{nb} recherches ajoutées ce mois-ci",
      totalResearches: "Recherches totales",

      validatedExercisesInMonth: "{nb} exercices revues ce mois-ci",
      validatedCoursesInMonth: "{nb} cours revus ce mois-ci",
      validatedExercises: "Validation des exercices",
      validatedCourses: "Validation des cours",
      validatedExercisesBySubject: "Validation des sujets",
      validatedCoursesBySubject: "Validation des sujets",

      validatedFilter: "Validé",
      notValidatedFilter: "En attente",
      rejectedFilter: "Rejeté",
      reason: "Raison",
      exerciseReview: "Validation des exercices",
      courseReview: "Validation des cours",
      review: "Révision",

      allTeachers: "Tous les enseignants",
      exercisesValidation: "Validation des exercices",
      coursesValidation: "Validation des cours",
      validate: "Valider",
      confirmValidationHomework:
        "Êtes-vous sûr de vouloir valider cette exercice ?",
      confirmValidationCourse: "Êtes-vous sûr de vouloir valider ce cours?",
      acceptValidate: "Oui, validez-le !",
      validated: "Validé !",
      validatedHomeworkInfo: "L'exercice a été validé avec succès !",
      validatedCourseInfo: "Le cours a été validé avec succès !",

      confirmRejectHomework:
        "Êtes-vous sûr de vouloir rejeter cette exercice ?",
      confirmRejectCourse: "Êtes-vous sûr de vouloir rejeter ce cours ?",
      acceptReject: "Oui, rejete-le!",
      rejected: "Rejeté",
      rejectedHomeworkInfo: "L'exercice a été rejeté avec succès !",
      rejectedCourseInfo: "Le cours a été rejeté avec succès !",

      specificExercises: "Exercices spécifiques",
      nbtotalExercises: "Total d'exercices : {nb}",
      finishedExercises: "Exercices finis : {nb}",
      notFinishedExercises: "Exercices non terminés : {nb}",
      submissionsState: "État des soumissions",
      submissionsStateTitle:
        "Soumissions des étudiants au cours de ce trimestre",
      incompleted: "Manquants",
      lateFinish: "Terminé en retard",
      completeInTime: "Terminé à temps",
      assign: "Attribué",
      completed: "Terminé",
      missing: "Manquant",

      nbCourses: "{nb} cours",
      nbExercises: "{nb} exercices",
      totalCourses: "Total cours",
      totalExercises: "Total exercices",
      added: "ajouté",
      addedCoursesInMonth: "{nb} cours ajoutés ce mois-ci",
      addedExercisesInMonth: "{nb} exercices ajoutés ce mois-ci",
      addedCoursesInTrimester: "{nb} cours ajoutés au cours de ce trimestre",
      addedExercisesInTrimester:
        "{nb} exercices ajoutées au cours de ce trimestre",
      addedCoursesInYear: "{nb} cours ajoutés cette année",
      addedExercisesInYear: "{nb} exercices ajoutés cette année",
      state: "État",
      classrooms: "Classes",
      chooseClassrooms: "Choisir les classes",
      requiredClassroom: "Les classes sont un champ obligatoire",
      allClassrooms: "Toutes les classes",
      myCourses: "Mes cours",
      myExercises: "Mes exercices",
      specificFor: "Spécifique pour",
      allStudent: "Tous les éléves",
      specificStudent: "Éléves spécifiques",
      students: "Éléves",
      chooseStudents: "Choisissez les éléves",
      requiredStudents: "Éléves est un champ obligatoire",
      subjects: "Matières",
      subject: "Matière",
      courses: "Cours",
      course: "Cours",
      exercises: "Exercices",
      exercise: "Exercice",
      nbrCourses: "Il y a {nb} cours",
      nbrExercises: "Il y a {nb} exercices",
      title: "Titre",
      lastComment: "Dernier commentaire par",
      teacher: "Enseignant",
      publicationDate: "Date de publication",
      search: "Recherche",
      deadline: "Date limite",
      module: "Module",
      description: "Description",
      privateMessage: "Message privé",
      resources: "Ressources",
      noResources: "Aucun fichiers",
      homework: "Devoir maison",
      marked: "Noté",
      notMarked: "Non noté",
      yes: "Oui",
      no: "Non",
      drobFiles: "Déposez les fichiers ici",
      fileSize: "Fichiers doivent être d'une taille inférieure à 50 Mo",
      reply: "Réponse",
      addExercise: "Ajout d'un nouveau exercice",
      editExercise: "Modifier l'exercice",
      add: "Ajouter",
      addedExercise: "L'exercice a été ajouté avec succès!",
      cancel: "Annuler",
      chooseSubject: "Choisir une matière",
      chooseDate: "Choisir une date",
      chooseTitle: "Choisir un titre",
      chooseModule: "Choisir un module",
      confirmDeleteExercise: "Voulez-vous vraiment supprimer cet exercice ?",
      deleteExerciseWarn: "Vous ne pourrez pas revenir en arrière !",
      acceptDelete: "Oui, supprimez-le !",
      deleted: "Supprimé !",
      deletedExerciseInfo: "Votre exercice a été supprimé.",
      edit: "Modifier",
      editedExerciseInfo: "L'exercice a été modifié avec succès !",
      okay: "D'accord!",
      infoExercise: "Détails de l'exercice",
      delete: "Supprimer",

      addCourse: "Ajout d'un nouveau cours",
      editCourse: "Modifier le cours",
      addedCourse: "Le cours a été ajouté avec succès !",
      infoCourse: "Détails du cours",
      confirmDeleteCourse: "Voulez-vous vraiment supprimer ce cours ?",
      deleteCourseWarn: "Vous ne pouvez pas revenir en arrière!",
      deletedCourseInfo: "Votre cours a été supprimé avec succès !",
      editedCourseInfo: "Le cours a été modifié avec succès!",

      requiredSubject: "Matière est un champ obligatoire",
      requiredModule: "Module est un champ obligatoire",
      requiredTitle: "Titre est un champ obligatoire",

      requiredLevels: "Niveaux est un champ obligatoire",
      requiredDesc: "La description est un champ obligatoire",
      requiredTopic: "Le sujet est un champ obligatoire",
      requiredDate: "La date est un champ obligatoire",
      requiredTeachers: "Enseignants est un champ obligatoire",
      durationRule: "La durée doit être supérieure à 1 heure",

      errorText: "Erreur inattendue",
      tryAgain: "Veuillez réessayer",

      studentSubmissions: "Soumissions des étudiants",
      submissions: "Soumissions",
      submittedAt: "Soumis le ",
      notNoted: "Non noté",
      submissionDate: "Date de soumission",
      submissionMethod: "Méthode de soumission",
      files: "Des fichiers",
      note: "Note",
      saveNote: "Enregistrer",
      noteRule: "La note doit être comprise entre 0 et 20",
      submitted: "Soumis",
      allTrimesters: "Toutes les trimestres",
      studentSubmission: "Soumission de l'étudiant",
      updatedSubmissionMark: "La soumission a été mise à jour avec succès!",
      allSubjects: "Toutes les matières",

      quiz: "Quiz",
      question: "Question",
      answers: "Réponses",
      correctAnswers: "Réponses correctes",
      wrongAnswers: "Mauvaises réponses",
      requiredQuestion: "La question est un champ obligatoire",
      addCorrectAnswer: "Ajouter la bonne réponse",
      addWrongAnswer: "Ajouter une mauvaise réponse",
      pleaseFillOrDeleteAnswers:
        "Merci de remplir les question ou réponses vides",
      addAnswer: "Ajouter une réponse",
      removeQuestion: "Supprimer la question",
      addQuestion: "Ajouter une question",
      isCorrect: "Bonne réponse",
    },

    punishments: {
      notFound: "Aucune sanctions n'est trouvée",
      punishments: "Sanctions",
      punishment: "Sanction",
      student: "Éléve",
      students: "Éléves",
      time: "Temps",
      reason: "Raison",
      description: "Description",
      valid: "Validé",
      notValidated: "Non validé",
      rejected: "Rejeté",
      actions: "Actions",
      edit: "Modifier",
      delete: "Supprimer",
      add: "Ajouter",
      punishmentSearch: "Recherche des sanctions",
      confirmDelete: "Êtes-vous sûr?",
      yes: "Oui",
      cancel: "Annuler",
      punishmentType: "Type de la sanction",
      punishmentEdit: "Modifier une sanction",
      punishmentAdd: "Ajouter une nouvelle sanction",
      close: "Fermer",
      times: "Temps",
      chooseStudent: "Choisir un élève",
      choosePunishment: "Choisir une sanction",
      chooseDate: "Choisir la date",
      writeReason: "Saisir une raison",
      writeDescription: "Saisir un description",
      added: "Ajout effectué avec succès !",
      edited: "Modification effectué avec succès !",
      deleted: "Supprimé !",
    },
    notes: {
      areYouSureToDeleteMark: "Voulez-vous vraiment supprimer la note?",
      invalidMarks:
        "Veuillez vous assurer que vos notes sont comprises entre 0 et 20.",
      notCorrectMarks:
        "Veuillez vous assurer que vos notes ont été saisies correctement.",
      missedSubsubjects:
        "Veuillez compléter toutes les notes manquantes pour ({name})",
      notes: "Notes",
      note: "Note",
      subject: "Matière",
      trimester: "Trimestre",
      edit: "Modifier",
      confirm: "Confirmer",
      cancel: "Annuler",
      student: "Éléve",
      updated: "Note mise a jour!",
      noEmpty: "Note ne doit pas être vide!",
      noInvalid: "La note doit être comprise entre 0 et 20 !",
      chooseSubject: "Choisir une matière",
      chooseTrimester: "Choisir une trimestre",
      trimester1: "Trimestre 1",
      trimester2: "Trimestre 2",
      trimester3: "Trimestre 3",
      gradeState: "État des notes",
      stateTitle: "Notes des éléves de ce trimestre",
      lessThan: "Moins que {nb}",
      moreThan: "Plus que {nb}",
      between: "Entre",
      aAndB: "{a} et {b}",
      save: "Enregistrer",
      saveAll: "Enregistrer tout",
      savedAll: "Toutes les notes sont enregistrées avec succès !",
      noteRemoved: "La note a été supprimée avec succès",
      medicalNote: "Note médicale",
      unmarkedStudents: "Notes manquantes",
      followingStudentsUnmarked: "Toutes les notes doivent être saisies pour les élèves suivants",
      generateAnyway: "Enregistrer quand même",
      missingSubSubjects: "Sous-matières manquantes",
      confirmation: "Valider les notes",
      confirmValidationWarning: "Une fois validées, ces notes ne pourront plus être modifiées.",
      impossibleToModify: "Impossible de modifier",
      alreadyValidated: "Les notes de cette matière sont déjà validées, vous ne pouvez ni les modifier ni les supprimer"
    },
    attendance: {
      attendanceState: "État de présence",
      titleState: "Assiduité des élèves ce mois-ci",
      sessions: "Séances le {date}",
      chooseSession: "Choisir une séance",
      delayInMonth: "Retard en ce mois",
      absenceInMonth: "Absence en ce mois",
      presenceInMonth: "Présence en ce mois",
      absent: "Absent",
      delay: "Retard",
      absents: "Absents",
      delays: "Retards",
      present: "Présent",
      updated: "Assiduité mise a jour!",
      inThisYear: "Cette année",
      totalAbsents: "Total des absences : {nb}",
      totalDelays: "Total des retards : {nb}",
    },

    classInfo: {
      numberStudent: "Il y a {nb} éléves",
      studentList: "La liste des éléves",
      attendance: "Assiduité",
      punishments: "Sanctions",
      courses: "Cours",
      exercises: "Exercices",
      girls: "Filles",
      boys: "Garçons",
      register: "Registre",
      notes: "Notes",
      submissions: "Soumissions",
      appreciations: "Appréciations",
      appreciationPeda: "Appréciations pédagogiques",
      appreciationS: "Appréciations pilote",
      appreciationVS: "Appréciations spécifiques",
      markSpecific: "Notes spécifique",
      markPeda: "Notes pédagogiques",
      markPilote: "Notes pilote",

    },
    classList: {
      listYourClasses: "La liste de vos classes",
      numberClasses: "{nb} classes",
      class: "Classe",
      subject: "Matière",
      action: "Action",
      counselor: "Consulter",
      findStudent: "Chercher éléve",
    },
    timetable: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
      lesson: "leçon",
      skip: "Passer",
      cabinet: "Salle",
      court: "cours",
      break: "Pause",
      classRoom: "Classe",
      print: "Imprimer",
      noData: "Pas de données",
    },
    feedback: {
      send: "Envoyer",
      feedback: "Avis",
      paragraphe1: "Madame, Monsieur,  ",
      paragraphe2: "Veuillez écrire vos commentaires. ou signaler un problème",
      paragraphe3: "",
      paragraphe4: "",
      placeholder: "Ecrivez votre avis s'il vous plait ...",
      images: "Images",
      audio: "Audio",
      text: "Message",
      record: "Enregistrement",
    },
    news: {
      reaction: "Vos réactions",
      totalPosts: "Total de publications",
      newPosts: "Nouvelles Publications",
      noNews: "L'administration n'a publié aucune actualité",
    },
    editProfile: {
      photo: "Photo",
      father: "Nom et prénom du père",
      mother: "Nom et prénom du mère",
      motherPhone: "Numéro du téléphone de la mère",
      fatherPhone: "Numero du téléphone du père",
      save: "Sauvegarder les changements",
      discard: "Annuler",
      wait: "Attendez s'il vous plait ...",
      edit: "Changer les informations personnelles",
      allowedFiles: "Les types autorisés: png, jpg, jpeg.",
      motherJob: " Profession de la mère",
      fatherJob: "Profession du père ",
      adress: "Adresse",

      general: "Général",
      settings: "Paramètres",
      fullname: "Nom et prénom",
      firstname: "Prénom",
      lastname: "Nom",
      birthday: "Date de naissance",
      telephoneNumber: "Numéro du téléphone",
      gender: "Le sexe",
      address: "Adresse",
      editProfile: "Editer le profil",
      profileDetails: "Détails du profil",
      profileDetailsUpdated:
        "Les détails de votre profil ont été mis à jour avec succès!",
      boy: "Garçon",
      girl: "Fille",
      verified: "Vérifié",
      allowedAvatarFiles: "Types de fichiers autorisés : png, jpg, jpeg.",
      cancel: "Annuler",
      saveChanges: "Sauvegarder les modifications",
      securitySettings: "Les paramètres de sécurité",
      currentPasswordIncorrect:
        "Le mot de passe actuel est incorrect, veuillez réessayer",
      emailAddress: "Adresse email",
      changeEmail: "Changer l'email",
      updateEmail: "Mettre à jour l'email",
      enterNewEmail: "Saisissez une nouvelle adresse email",
      confirmPassword: "Confirmez le mot de passe",
      password: "Mot de passe",
      resetPassword: "Réinitialiser le mot de passe",
      passwordRule:
        "Le mot de passe doit comporter au moins 8 caractères et contenir des symboles",
      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmez le nouveau mot de passe",
      updatePassword: "Mettre à jour le mot de passe",
      profileCompletion: "Achèvement du profil",
      accountSettings: "Paramètres du compte",
      myProfile: "Mon profil",
      student: "Étudiant",
      updatedPhoto: "Photo mise à jour!",
      updatePhotoError: "Erreur interne",

      requiredFirstname: "Prénom est un champ obligatoire",
      requiredLastname: "Nom est un champ obligatoire",
      requiredPhone: "Téléphone est un champ obligatoire",
      requiredAddress: "Adresse est un champ obligatoire",

      requiredCurrentPassword:
        "Le mot de passe actuel est un champ obligatoire",
      requiredNewPassword: "Le nouveau mot de passe est un champ obligatoire",
      requiredConfirmPassword:
        "La confirmation du mot de passe est un champ obligatoire",
      passwordConfirmationCondition:
        "La confirmation du mot de passe doit contenir au moins 4 caractères",
      passwordNewCondition:
        "Le nouveau mot de passe doit comporter au moins 4 caractères",
      passwordMustMatch: "Les mot de passes doivent être identique",
      passwordUpdated: "Votre mot de passe a été mis à jour avec succès",
      emailUpdated: "Votre email a été mis à jour avec succès",
      speciality: "Spécialité",
      cin: "Carte d'identité",
      civilstatus: "État civil",
      requiredCivilstatus: "L'état civil est un champ obligatoire",
      requiredCin: "La carte d'identité est un champ obligatoire",
      requiredSpeciality: "La spécialité est un champ obligatoire",
      invalidCin:
        "Le numéro de la carte d'identité doit commencer par 0 ou 1 et contient 8 chiffres",
      invalidPhone: "Le numéro du téléphone doit contenir 8 chiffres",
      invalidEmail: "L'email doit être valide",
      requiredEmail: "L'email est un champ obligatoire",
      requiredSchool: "Le nom de l'école est un champ obligatoire",
      unavailableEmail: "L'email n'est pas disponible !",
    },
    dashboard: {
      timetable: "Calendrier",
      phoneNumber: "Numéro du téléphone",
      headMaster: "Directeur",
      responsibleLevels: "Niveaux responsables",
      responsibleSubjects: "Matières responsables",
      year_1: "Préparatoire",
      year_2: "Préscolaire",
      year1: "1ère année",
      year2: "2ème année",
      year3: "3ème année",
      year4: "4ème année",
      year5: "5ème année",
      year6: "6ème année",
      year7: "7ème année",
      year8: "8ème année",
      year9: "9ème année",
      dashboard: "Accueil",
      comments: "Commentaires",
      likes: "Mentions de j'aime",
      settings: "Paramètres",
      consultpayment: "Consulter Paiement",
      mychild: "Mon enfant",
      details: "Détails",
      actualites: "Actualités",
      canteen: "Cantine",
      payment: "Paiement",
      report: "Bulletins",
      avertissements: "Avertissements",
      schedule: "Emploi",
      reclamations: "Réclamations",
      profile: "Mon Profil",
      language: "Langue",
      signout: "Déconnexion",
      arabic: "Arabe",
      english: "Anglais",
      french: "Français",
      changeSchoolarYear: " Changer l'année scolaire ",
      thisWeekMeeting: "Réunions de la semaine",
    },
    report: {
      informations:
        "L'administration ajoutera les bulletins de notes à la fin de chaque trimestre pour que vous pouvez suivre le niveau de votre enfant .",
      update: "Dernier mis à jour : ",
      premsem: "1er trimestre",
      deuxsem: "2ème trimestre",
      troisem: "3ème trimestre",
      download: "Télécharger",
      unavailable: "Bulletin indisponible !",
    },
    canteen: {
      informations:
        "La cantine, c’est souvent la solution du midi. Une nouvelle aventure pour vos enfants qui, pour la plupart, en redemandent après y avoir goûté ! On ne rigole pas avec l’alimentation des enfants ! L’école leur propose des menus équilibrés et variés et, même si elle ne peut pas assurer à elle seule leur équilibre alimentaire, les repas du midi ont le mérite, en tout cas, de répondre à leurs besoins.",
      informations2: "L'administration ajoutera les plats de chaque jour .",
      choosedate: "Choisissez une date :",
      unavailable: "Données indisponibles !",
      forthisdate: "Pour cette date :",
      schooloffers: "Notre école propose",
    },
    schedule: {
      informations:
        "L'administration ajoutera l'emploi de votre enfant pour chaque trimestre .    ",
      unavailable: "Emploi indisponible !",
    },

    avertissement: {
      teacher: "Professeur",
      subject: "Matière",
      reason: "Raison",
      totalnumber: "Nombre Total",
      notes: "Notes",
      importantInformations: "Informations importantes !",
      informations:
        "Les sanctions contribuent non à exclure de la classe mais à y intégrer : elles reconnaissent à l'élève la responsabilité de ses actes et, en même temps, lui permettent de revenir dans le collectif dont il s'est lui-même exclu.Tout avertissement est validé par notre administration.",
      informations2:
        "Pour plus d'information , veuillez contacter l'administartion.",
      unavailable: "Aucun avertissement!",
    },
    payment: {
      scolarityPayment: "Paiement Scolarité",
      activityPayment: "Paiement Activités",
      finalStatus: "Statut Final",
      month: "Mois",
      state: "Etat",
      activityName: "Nom d'activité",
      fees: "Frais",
      progress: "Progrès du paiement",
      singlePayment: "Paiement unique",
      monthlyPayment: "Paiement mensuel",
      monthlyFees: "Frais mensuels",
      afterReduction: "après réduction",
      inscriptionFees: "Frais d'inscription",
      inscriptionReduction: "Réduction d'inscription",
      monthlyReduction: "réduction mensuelle",
      monthly: "Mensuel",
      inscription: "Inscription",
    },
    settings: {
      options: "Options",
      child: "Enfant",
      year: "Année Scolaire",
      apply: "Appliquer",
      reset: "Annuler",
      chooseChild: "Choisir parmi vos enfants",
      chooseYear: "Choisir l'année scolarie",
    },
    pleaseWait: "S'il vous plaît, attendez...",

    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    arabic: "Arabe",
    english: "Anglais",
    french: "Français",
  },
  ar: {
    swalMessages: {
      somethingWrong: "لقد حدث خطأ ما",
      requiredFields: "يرجى ملء جميع الأماكن المطلوبة"
    },
    readAndAccept: "قرأت وأوافق على",
    termsAndConditions: "الشروط والأحكام",
    advisorLabel: "متفقد",
    teacherLabel: "معلم",
    all: "الكل",
    availability: {
      saved: "تم حفظ توفرك بنجاح",
      indicateAvailability: "تحديد توفرك ليوم {day}",
      title: "التوفر",
      titleTeacher: "توفر المعلمين",
      available: "متاح",
      notAvailable: "غير متاح",
      note: "يرجى النقر على الفترات الزمنية التي تتوفر فيها حتى يتمكن الإدارة من إعداد الجدول الزمني بسهولة أكبر.",
    },
    sghartoon: {
      courseList: "قائمة الدورات",
      courseDetails: "تفاصيل الدورة",
      watch: "مشاهدة",
      videos: "فيديوهات",
    },
    calendar: {
      month: "شهر",
      week: "أسبوع",
      day: "يوم",
      addEvent: "إضافة حدث",
      addEventTitle: "إضافة حدث جديد",
      duration: "المدة",
      durationRequired: "المدة مطلوبة",
      exam: "إمتحان",
      party: "حفلة",
      excursion: "رحلة",
      meeting: "إجتماع",
      other: "أخرى",
      eventType: "نوع الحدث",
      color: "اللون",
      typeRequired: "نوع الحدث مطلوب",
      editEventTitle: "تعديل الحدث",
      editEvent: "تعديل الحدث",
      editedEvent: "تم تعديل الحدث بنجاح",
      deletedEvent: "تم حذف الحدث بنجاح",
      addedEvent: "تم إضافة الحدث بنجاح",
      tous: "الكل",
      teachers: "المعلمون",
      students: "الطلاب",
      administration: "الادارة",
      parents: "الآباء",
      title: "العنوان",
      target: "هدف",
      startDate: "تاريخ البداية",
      endDate: "تاريخ النهاية",
    },
    chat: {
      ROOMS_EMPTY: "لا توجد غرف",
      ROOM_EMPTY: "لم يتم اختيار غرفة",
      NEW_MESSAGES: "رسائل جديدة",
      MESSAGE_DELETED: "تم حذف هذه الرسالة",
      MESSAGES_EMPTY: "لا توجد رسائل",
      CONVERSATION_STARTED: "بدأت المحادثة في:",
      TYPE_MESSAGE: "اكتب الرسالة",
      SEARCH: "يبحث",
      IS_ONLINE: "متصل",
      LAST_SEEN: "اخر ظهور ",

      parentDetails: "معلومات الوالدين",
      startNewConversationWithParent: "إنشاء محادثة جديدة مع أحد الأولياء",
      addParent: "إضافة الولي",
      addTeacher: "إضافة مدرس",
      chatWithParents: "محادثة مع الأولياء",
    },
    downloadGuide: "EdTeacher تحميل دليل",
    notification: {
      notifications: "الإشعارات",
      newNotification: "إشعار جديد",
      viewAll: "عرض الكل",
    },
    appreciation: {
      yourAppreciation: "اكتب ملاحظة",
      saved: "تم حفظ ملاحظتك بنجاح",
      delete: "تم حذف تقديرك بنجاح!",
      appreciation: "الملاحظة",
      appreciations: "الملاحظات",
      moyenne: "معدل المجال",
      teacherAppreciations: "ملاحظات المعلم",
      confirmCancel: "هل أنت متأكد من رغبتك في الإلغاء؟",
      unsavedchanges: "تعديلات غير محفوظة!",
      unsavedchangesContent: "هل تريد حقا المغادرة؟ لم تقم بحفظ تعديلاتك!",
    },
    resetpassword: {
      linkExpired: "لقد انتهت صلاحية هذا الرابط بالفعل",
      linkExpiredDesc: "لقد انتهت صلاحية هذا الرابط، يرجى إعادة المحاولة",
      passwordChanged: "تم تغيير كلمة المرور الخاصة بك بنجاح",
      close: "إغلاق",
      forgotPassword: "نسيت كلمة المرور",
      forgotPasswordText:
        "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك. إذا لم يكن لديك بريد إلكتروني ، يرجى الاتصال بالإدارة",
      resetPasswordReqested: "طلب إعادة تعيين كلمة المرور",
      resetPasswordReqestedDesc:
        "لقد أرسلنا لك بريدًا إلكترونيًا ، يرجى التحقق منه لإعادة تعيين كلمة المرور الخاصة بك!",
      cancel: "إلغاء",
      Submit: "تفعيل",
      emailNotExist: "هذا البريد الإلكتروني غير موجود",
    },
    meeting: {
      noMeetings: "لا يوجد إجتماعات",
      meetings: "الاجتماعات",
      myMeetings: "إجتماعاتي",
      calendar: "تقويم",
      addMeeting: "إضافة الاجتماع",
      topic: "عنوان",
      addNewMeeting: "إضافة اجتماع جديد",
      editMeeting: "تحرير الاجتماع",
      duration: "(ساعات) مدة",
      date: "تاريخ",
      attendances: "الحضور",
      rejected: "لا يمكن الحضور",
      confirmed: "قادر على الحضور",
      createdBy: "انشأ من قبل",
      status: "حالة",
      submit: "حفظ",

      meetingAdded: "تمت إضافة الاجتماع بنجاح!",
      meetingEdited: "تم تحرير الاجتماع بنجاح!",
      meetingDeleted: "تم حذف اجتماعك بنجاح!",
      confirmDelete: "هل أنت متأكد من رغبتك في حذف هذا الاجتماع؟",
      submittedAttendance: "تم تقديم حضورك بنجاح!",
      meetingError:
        "معذرة ، يبدو أنه تم اكتشاف بعض الأخطاء ، يرجى المحاولة مرة أخرى.",
    },
    teacher: {
      myTeachers: "أساتذتي",
      findTeacher: "بحث",
      numberTeacher: "هناك {nb} أستاذ",
      teacherList: "قائمة الأستاذة",
      teacherProfile: "ملف الأستاذ",
      grade: "الرتبة",
    },
    stats: {
      validationActivity: "نشاط التأكيدات",
      studentGradePerformance: "أداء الأعداد",
      studentGradePerformanceThisTrimester: "أداء الأعداد في هذه الثلاثية",
      maxMark: "أفضل عدد",
      minMark: "أقل عدد",
      studentMark: "عدد التلميذ",
      thisWeekStats: "احصائيات هذا الأسبوع",
      numberHoursInWeek: "عدد الساعات هذا الأسبوع",
      numberHoursToday: "عدد ساعات اليوم",
      numberHoursTomorrow: "عدد ساعات الغد",
      averageHoursPerDay: "معدل ساعات العمل باليوم",
      todayAndTomorrow: "اليوم و غدا",
      nbHours: "{nb} ساعات",

      attendanceStats: "احصائيات الحضور",
      classroomAttendanceThisMonth: "حضور الفصل هذا الشهر",
      numberAbsentsInYear: "عدد الغيابات هذه السنة",
      numberDelaysInYear: "عدد التأخرات هذه السنة",

      studentsStats: "إحصائيات التلاميذ",
      numberOfGirls: "عدد الإناث",
      numberOfBoys: "عدد الفتيان",

      activity: "النشاط",
      byMonth: "الأشهر",
      bySubject: "المواد",
    },
    student: {
      studentProfile: "ملف التلميذ",
      findSubject: "البحث عن مادة",
      myStudents: "تلاميذي",
      studentList: "قائمة التلاميذ",
      numberStudent: "تلميذ {nb} يوجد",
      findStudent: "البحث عن تلميذ",
      photo: "الصورة",
      fullname: "الإسم الكامل",
      gender: "الجنس",
      birthdate: "تاريخ الولادة",
      address: "العنوان",
      parentSheet: "ملف الوالدين",
      female: "فتاة",
      male: "فتى",
      details: "التفاصيل",
      fatherSection: "قسم الأب",
      motherSection: "قسم الأم",
      phone: "رقم الهاتف",
      parentMoreInfo: ".لمزيد من المعلومات، يرجى الإتصال بالإدارة",
      close: "الغاء",
    },
    comment: {
      confirmDelete: "هل أنت متأكد من رغبتك في حذف هذا التعليق؟",
      deleted: "تم حذف التعليق بنجاح",
      comments: "التعليقات",
      reply: "تعليق",
    },
    course: {
      noTeachersFound: "لم يتم العثور على مدرس للمادة المحددة",
      filesTooLarge: "الملفات التي أرسلتها كبيرة جدًا!",
      maxFilesUploadExceed:
        "لقد تجاوزت الحد الأقصى لعدد الملفات المسموح برفعها. لا يمكنك رفع أكثر من 15 ملفًا.",
      others: "أخرى",
      photos: "الصور",

      researchSubmitted: "تم تقديم بحثك بنجاح",

      details: "التفاصيل",
      schoolarYear: "السنة الدراسية",
      uploadFiles: "رفع ملفات",
      selectSubmissionMethod: "إختر طريقة التسليم",
      writeAnswer: "كتابة إجابة",
      homeworkSubmitted: "تم تسليم واجبك بنجاح",
      answer: "إجابة",
      submit: "إجابة",
      invalidSubmission: "حدث خطأ ما",
      invalidSubmissionText:
        "يجب ان تقوم بتسليم ملفات او ان تقوم بكتابة الإجابة",

      specificTeacher: "أستاذ محدد",
      teachers: "الأساتذة",
      chooseTeachers: "إختر الأساتذة",
      teacherSubmissions: "تقديمات الأساتذة",

      levels: "المستويات",
      allLevels: "كل المستويات",
      chooseLevels: "إختر المستويات",

      myResearches: "بحوثي",
      research: "بحث",
      researches: "بحوث",
      nbrResearches: "بحث {nb}",
      nbResearches: "بحث {nb}",
      addResearch: "إضافة بحث جديد",
      editResearch: "تعديل البحث",
      infoResearch: "تفاصيل البحث",
      confirmDeleteResearch: "هل انت متأكد من رغبتك في حذف هذا البحث؟",
      deleteResearchWarn: "لا يمكنك التراجع",
      deletedResearchInfo: "!تم حذف البحث الخاص بك بنجاح",
      editedResearchInfo: "!تم تعديل البحث الخاص بك بنجاح",
      addedResearch: "!تمت إضافة البحث بنجاح",

      addedResearchesInMonth: "بحث تم إضافته هذا الشهر {nb}",
      totalResearches: "عدد البحوث",

      validatedExercisesInMonth: "تم مرجاعة {nb} تمرين هذا الشهر",
      validatedCoursesInMonth: "تم مراجعة {nb} درس هذا الشهر",
      validatedExercises: "التمارين المؤكدة",
      validatedCourses: "الدروس المؤكدة",
      validatedExercisesBySubject: "التمارين المؤكدة في كل مادة",
      validatedCoursesBySubject: "الدروس المؤكدة في كل مادة",

      validatedFilter: "مأكد",
      notValidatedFilter: "غير مأكد",
      rejectedFilter: "مرفوض",
      reason: "السبب",
      exerciseReview: "معاينة التمرين",
      courseReview: "معيانة الدرس",

      review: "معاينة",
      allTeachers: "كل الأستاذ",
      exercisesValidation: "تأكيد التمارين",
      coursesValidation: "تأكيد الدروس",
      validate: "تأكيد",
      confirmValidationHomework: "هل انت متأكد من رغبتك في تأكيد هذا التمرين؟",
      confirmValidationCourse: "هل انت متأكد من رغبتك في تأكيد هذا الدرس؟",
      acceptValidate: "نعم، تأكيد",
      validated: "تم التأكيد !",
      validatedHomeworkInfo: "! تم تأكيد التمرين بنجاح",
      validatedCourseInfo: "! تم تأكيد الدرس بنجاح",

      confirmRejectHomework: "هل انت متأكد من رغبتك في رفض هذا التمرين؟",
      confirmRejectCourse: "هل انت متأكد من رغبتك في رفض هذا الدرس؟",
      acceptReject: "نعم، أرفض",
      rejected: "تم الرفض !",
      rejectedHomeworkInfo: "! تم رفض التمرين بنجاح",
      rejectedCourseInfo: "! تم رفض الدرس بنجاح",

      specificExercises: "التمارين المميزة",
      nbtotalExercises: "{nb} : عدد التمارين",
      finishedExercises: "{nb} : التمارين المكتملة",
      notFinishedExercises: "{nb} : التمارين الغير المكتملة",
      submissionsState: "احصائيات التقديمات",
      submissionsStateTitle: "تقديمات هذا الثلاثي الخاصة بالتلميذ",
      incompleted: "غير مكتمل",
      lateFinish: "مكتمل مع التأخر",
      completeInTime: "مكتمل في الوقت",
      assign: "تعيين",
      completed: "مكتمل",
      missing: "غير مكتمل",
      nbCourses: "{nb} دروس",
      nbExercises: "{nb} تمارين",
      totalCourses: "عدد الدروس",
      totalExercises: "عدد التمارين",

      added: "تمت إضافة",
      addedCoursesInMonth: "تمت إضافة {nb} دروس هذا الشهر ",
      addedExercisesInMonth: "تمت إضافة {nb} تمارين هذا الشهر ",
      addedCoursesInTrimester: "تمت إضافة {nb} دروس هذا الثلاثي ",
      addedExercisesInTrimester: "تمت إضافة {nb} تمارين هذا الثلاثي ",
      addedCoursesInYear: "تمت إضافة {nb} دروس هذا العام ",
      addedExercisesInYear: "تمت إضافة {nb} تمارين هذا العام ",
      state: "الإحصائيات",
      classrooms: "الإقسام",
      chooseClassrooms: "إختر الأقسام",
      requiredClassroom: "الأقسام مطلوب",
      allClassrooms: "كل الأقسام",
      myCourses: "دروسي",
      myExercises: "تماريني",
      specificFor: "محدد إلى",
      allStudent: "كل التلاميذ",
      specificStudent: "تلاميذ معينين",
      students: "التلاميذ",
      chooseStudents: "إختر التلاميذ",
      requiredStudents: "التلاميذ مطلوب",
      subjects: "المواد",
      subject: "المادة",
      courses: "الدروس",
      course: "الدرس",
      exercises: "التمارين",
      exercise: "التمرين",
      nbrCourses: "يوجد {nb} درس",
      nbrExercises: "يوجد {nb} تمرين",
      title: "العنوان",
      lastComment: "آخر تعليق",
      teacher: "الأستاذ",
      publicationDate: "تاريخ النشر",
      search: "بحث",
      deadline: "الموعد النهائي",
      module: "الوحدة",
      description: "الوصف",
      privateMessage: "رسالة خاصة",
      resources: "المصادر",
      noResources: "لا يوجد مصادر",
      homework: "الواجب",
      marked: "بالنقاط",
      notMarked: "بدون نقاط",
      yes: "نعم",
      no: "لا",
      drobFiles: "اسحب الملفات هنا",
      fileSize: "ملفات بحجم أقل من 50 ميجا بايت",
      reply: "رد",
      addExercise: "إضافة تمرين جديد",
      edit: "تعديل",
      add: "إضافة",
      addedExercise: "!تمت إضافة التمرين بنجاح",
      cancel: "إلغاء",
      confirmDeleteExercise: "هل أنت متأكد من رغبتك في حذف هذا التمرين؟",
      deleteExerciseWarn: "لا يمكنك التراجع",
      acceptDelete: "نعم، حذف",
      deleted: "!تم الحذف",
      deletedExerciseInfo: "!تم حذف التمرين بنجاح",
      editExercise: "تعديل التمرين",
      chooseSubject: "إختر المادة",
      chooseDate: "إختر التاريخ",
      chooseTitle: "إختر عنوانًا",
      chooseModule: "إختر الوحدة",
      editedExerciseInfo: "!تم تحديث التمرين بنجاح",
      okay: "!حسنا",
      infoExercise: "تفاصيل التمرين",
      delete: "حذف",

      addCourse: "إضافة درس جديد",
      editCourse: "تعديل الدرس",
      addedCourse: "!تمت إضافة الدرس بنجاح",
      infoCourse: "تفاصيل الدرس",
      confirmDeleteCourse: "هل أنت متأكد من رغبتك في حذف هذا الدرس؟",
      deleteCourseWarn: "لا يمكنك التراجع",
      deletedCourseInfo: "!تم حذف الدرس بنجاح",
      editedCourseInfo: "!تم تحديث الدرس بنجاح",

      requiredSubject: "المادة مطلوبة",
      requiredModule: "الوحدة مطلوبة",
      requiredTitle: "العنوان مطلوب",

      requiredLevels: "المستويات حقل مطلوب",
      requiredDesc: "الوصف مطلوب",
      requiredTopic: "الموضوع مطلوب",
      requiredDate: "التاريخ مطلوب",
      requiredTeachers: "حقل الأستاذة مطلوب",
      durationRule: "يجب أن تتجاوز المدة الساعة على الأقل",

      errorText: "شيئًا ما غير صحيح",
      tryAgain: "يرجى إعادة المحاولة مرة أخرى",

      studentSubmissions: "تسليمات التلاميذ",
      submissions: "التسليمات",
      submittedAt: "سُلم في",
      notNoted: "غير منقط",
      submissionDate: "تاريخ التسليم",
      submissionMethod: "طريقة التسليم",
      files: "الملفات",
      note: "النقطة",
      saveNote: "حفظ النقطة",
      noteRule: "النقطة يجب أن تكون مابين 0 و 20",
      submitted: "تم التسليم",
      allTrimesters: "جميع الثلاثيات",
      studentSubmission: "تسليمات التلميذ",
      updatedSubmissionMark: "تم تحديث تسليم التلميذ بنجاح",
      allSubjects: "جميع المواد",

      quiz: "إختبار قصير",
      question: "السؤال",
      answers: "الإجابات",
      correctAnswers: "الإجابات الصحيحة",
      wrongAnswers: "الإجابات الخاطئة",
      requiredQuestion: "السؤال خانة مطلوبة",
      addCorrectAnswer: "إضافة إجابة صحيحة",
      addWrongAnswer: "إضافة إجابة خاطئة",
      pleaseFillOrDeleteAnswers: "يرجى ملئ الاسألة أو الإجابات الفارغة",
      addAnswer: "إضافة إجابة",
      removeQuestion: "حذف السؤال",
      addQuestion: "إضافة سؤال",
      isCorrect: "إجابة صحيحة",
    },
    punishments: {
      notFound: "لم يتم العثور على عقوبات",
      punishments: "العقوبات",
      punishment: "العقوبة",
      student: "التلاميذ",
      students: "التلاميذ",
      time: "الوقت",
      reason: "السبب",
      description: "الوصف",
      valid: "ساري المفعول",
      notValidated: "غير مفعل",
      rejected: "مرفوض",

      actions: "أجراءات",
      edit: "تعديل",
      delete: "حذف",
      add: "إضافة",
      punishmentSearch: "البحث عن عقوبات",
      confirmDelete: "هل انت متأكد",
      yes: "نعم",
      cancel: "إلغاء",
      punishmentType: "نوع العقوبة",
      punishmentEdit: "تعديل العقوبة",
      punishmentAdd: "إضافة عقوبة جديدة",
      close: "اغلاق",
      times: "الاوقات",
      chooseStudent: "إختر تلميذ",
      choosePunishment: "إختر عقوبة",
      chooseDate: "إختر التاريخ",
      writeReason: "اكتب السبب",
      writeDescription: "اكتب الوصف",
      added: "!تمت الإضافة بنجاح",
      edited: "!تم التحديث بجاح",
      deleted: "!تم الحذف بنجاح!",
    },

    notes: {
      areYouSureToDeleteMark: "هل أنت متأكد من حذف العدد؟",
      invalidMarks: "يرجى التأكد من أن اعدادك بين 0 و 20",
      notCorrectMarks: "يرجى التأكد من إدخال اعدادك بشكل صحيح.",
      missedSubsubjects:
        "يبدو أنه لم يتم إكمال جميع الاعداد ({name}). يرجى إدخال جميع الاعداد المفقودة.",
      notes: "الأعداد",
      note: "العدد",
      subject: "المادة",
      trimester: "الثلاثية",
      edit: "تعديل",
      confirm: "تأكيد",
      cancel: "إلغاء",
      student: "التلميذ",
      updated: "!تم تحديث النقاط بنجاح",
      noEmpty: "!لنقاط لا يجب ان تكون فارغة",
      noInvalid: "يجب أن تكون النقاط بين 0 و 20!",
      chooseSubject: "إختر مادة",
      chooseTrimester: "إختر ثلاثية",
      trimester1: "الثلاثي الأول",
      trimester2: "الثلاثي الثاني",
      trimester3: "الثلاثي الثالث",
      gradeState: "احصائيات الدرجات",
      stateTitle: "درجات التلميذ في هذه الثلاثية",
      lessThan: "أقل من {nb} ",
      moreThan: "أكثر من {nb} ",
      between: "مابين",
      aAndB: "{a} و {b}",
      save: "حفظ",
      saveAll: "حفظ الكل",
      savedAll: "كل النقاط تم حفظها بنجاح",
      noteRemoved: "تمت إزالة العدد بنجاح",
      medicalNote: "مذكرة طبية",
      unmarkedStudents: "أعداد مفقودة",
      followingStudentsUnmarked: "يجب إدخال جميع الأعداد للتلاميذ التاليين",
      generateAnyway: "احفظ على أي حال",
      missingSubSubjects: "المواد الفرعية الناقصة",
      confirmation: "تأكيد الأعداد",
      confirmValidationWarning: "بمجرد التأكيد، لا يمكن تعديل هذه الأعداد",
      impossibleToModify: "لا يمكنك تعديل هذه الأعداد",
      alreadyValidated: "تم بالفعل اعتماد درجات هذه المادة، ولا يمكنك تعديلها أو حذفها"
    },
    attendance: {
      attendanceState: "احصائيات الحضور",
      titleState: "حضور التلميذ هذا الشهر",
      sessions: "{date} الحصص بتاريخ",
      chooseSession: "إختر الحصة",
      delayInMonth: "التأخرات هذا الشهر",
      absenceInMonth: "الغيابات هذا الشهر",
      presenceInMonth: "الحضور هذا الشهر",
      absent: "غائب",
      delay: "متأخر",
      present: "حاضر",
      absents: "غيابات",
      delays: "تأخرات",
      updated: "تم تحديث الحضور بنجاح",
      inThisYear: "في هذه السنة",
      totalAbsents: "{nb} : عدد الغيابات",
      totalDelays: "{nb} : عدد التأخرات",
    },

    classInfo: {
      numberStudent: "تلميذ {nb} يوجد",
      studentList: "قائمة التلاميذ",
      attendance: "الحضور",
      notes: "النقاط",
      punishments: "العقوبات",
      courses: "الدروس",
      exercises: "التمارين",
      girls: "الفتيات",
      boys: "الفتيان",
      register: "السجل",
      submissions: "التقديمات",
      appreciations: "ملاحظات",
      appreciationPeda: "الملاحظات البيداغوجيّة",
      appreciationS: "الملاحظات النموذجيّة",
      appreciationVS: "الملاحظات المخصصّة",
      markSpecific: "الأعداد المخصصة",
      markPeda: "الأعداد البيداغوجيّة",
      markPilote: "الأعداد النموذجيّة",
    },
    classList: {
      listYourClasses: "قائمة الأقسام الدراسية الخاصة بك",
      numberClasses: "قسم {nb}",
      class: "القسم",
      subject: "المادة",
      action: "أجراء",
      counselor: "مستشار",
      findStudent: "البحث عن تلميذ",
    },
    timetable: {
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الاربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
      lesson: "درس",
      skip: "تخطي",
      cabinet: "القاعة",
      court: "الفصل",
      break: "راحة",
      classRoom: "قسم",
      print: "طباعة",
      noData: "لا يوجد بيانات",
    },
    feedback: {
      send: "ارسال",
      feedback: "تعليق",
      paragraphe1: " ، سيدي و سيدتي ",
      paragraphe2: "الرجاء كتابة رأيك حول موقعنا، أو التبليغ عن مشكلة",
      paragraphe3: "",
      paragraphe4: "  ",
      placeholder: "بامكانك كتابة رايك ",
      images: "صور",
      audio: "صوت",
      text: "الرسالة",
      record: "تسجيل",
    },
    news: {
      reaction: "منشوراتك",
      totalPosts: "عدد المنشورات",
      newPosts: "مشاركات جديدة",
      noNews: "لم تنشر الإدارة أي أخبار بعد",
    },
    editProfile: {
      photo: "الصورة",
      father: "اسم الاب",
      mother: "اسم الام",
      motherPhone: "رقم هاتف الام",
      fatherPhone: "  رقم هاتف الاب",
      save: "تسجيل التغييرات",
      discard: "الغاء",
      wait: "انتظر قليلا ",
      edit: "تغيير المعلومات الشخصية",
      allowedFiles: " ",
      motherJob: " مهنة الام",
      fatherJob: " مهنة الاب",
      adress: "العنوان",

      general: "عام",
      settings: "الإعدادات",
      fullname: "الإسم الكامل",
      firstname: "الإسم",
      lastname: "اللقب",
      birthday: "تاريخ الولادة",
      telephoneNumber: "رقم الهاتف",
      gender: "الجنس",
      address: "العنوان",
      editProfile: "تعديل الملف الشخصي",
      profileDetails: "معلومات الملف الشخصي",
      profileDetailsUpdated: "!تم تحديث معلومات الملف الشخصي بنجاح",
      boy: "فتى",
      girl: "فتاة",
      verified: "مفعل",
      allowedAvatarFiles: "أنواع الملفات المسموح بها هي : png، jpg، jpeg.",
      cancel: "إلغاء",
      saveChanges: "حفظ التغيرات",
      securitySettings: "اعدادات الامان",
      currentPasswordIncorrect:
        "...كلمة المرور الحالية غير صحيحى. يرجى إعادة المحاولة",
      emailAddress: "البريد الإلكتروني",
      changeEmail: "تغيير البريد الإلكتروني",
      updateEmail: "تحديث البريد الإلكتروني",
      enterNewEmail: "البريد الإلكتروني الجديد",
      confirmPassword: "تأكيد كلمة المرور",
      password: "كلمة المرور",
      resetPassword: "إعادة تعيين كلمة المرور",
      passwordRule:
        "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وأن تحتوي على رموز",
      currentPassword: "كلمة المرور الحالية",
      newPassword: "كلمة المرور الجديدة",
      confirmNewPassword: "تأكيد كلمة المرور الجديدة",
      updatePassword: "تحديث كلمة المرور",
      profileCompletion: "استكمال الملف الشخصي",
      accountSettings: "إعدادات الحساب",
      myProfile: "ملفي الشخصي",
      student: "تلميذ",
      updatedPhoto: "!تم تغير الصورة",
      updatePhotoError: "حدث خطأ",

      requiredFirstname: "الإسم الأول مطلوب",
      requiredLastname: "اللقب مطلوب",
      requiredPhone: "رقم الهاتف مطلوب",
      requiredAddress: "العنوان مطلوب",

      requiredCurrentPassword: "كلمة المرور الحالية مطلوبة",
      requiredNewPassword: "كلمة المرور الجديدة مطلوبة",
      requiredConfirmPassword: "تأكيد كلمة المرور مطلوب",
      passwordConfirmationCondition:
        "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
      passwordNewCondition: "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
      passwordMustMatch: "كلمة المرور يجب أن تطابق",
      passwordUpdated: "!تم تحديث كلمة المرور بنجاح",
      emailUpdated: "تم تحديث البريد الالكتروني بنجاح",
      speciality: "التخصص",
      cin: "رقم بطاقة الهوية",
      civilstatus: "الحالة المدنية",
      requiredCivilstatus: "الحالة الاجتماعية مطلوب",
      requiredCin: "رقم بطاقة الهوية مطلوب",
      requiredSpeciality: "التخصص مطلوب",
      invalidCin:
        "يجب ان يبدأ رقم بطاقة الهوية بـ 0 أو 1 و أن يتكون من 8 ارقام",
      invalidPhone: "يجب ان يتكون رقم الهاتف من 8 ارقام على الاقل",
      invalidEmail: "البريد الالكتروني غير صحيح",
      requiredEmail: "البريد الالكتروني مطلوب",
      requiredSchool: "أسم المدرسة مطلوب",
      unavailableEmail: "!البريد الإلكتروني غير متوفر",
    },
    dashboard: {
      timetable: "جدول الأوقات",
      phoneNumber: "رقم الهاتف",
      headMaster: "المدير",
      responsibleLevels: "المستويات المسؤول عنها",
      responsibleSubjects: "المواد المسؤول عنها",
      year_1: "تحضيري",
      year_2: "ما قبل المدرسة",
      year1: "سنة أولى",
      year2: "سنة ثانية",
      year3: "سنة ثالثة",
      year4: "سنة رابعة",
      year5: "سنة خامسة",
      year6: "سنة سادسة",
      year7: "سنة سابعة",
      year8: "سنة ثامنة",
      year9: "سنة تاسعة",
      dashboard: "الإستقبال",
      comments: "التعليقات",
      likes: "الإعجابات",
      settings: "الإعدادات",
      consultpayment: "استشر الدفع",
      mychild: "ابني",
      details: "تفاصيل",
      canteen: "مطعم مدرسي",
      payment: "دفع",
      actualites: "مستجدات",
      report: "بطاقةالاعداد",
      avertissements: "تحذيرات",
      schedule: "جدول أوقات",
      reclamations: "شكاوي",
      profile: "ملفي",
      language: "لغة",
      signout: "تسجيل خروج",
      arabic: "العربية",
      english: "الإنجليزية",
      french: "الفرنسية",
      changeSchoolarYear: " تغير السنة الدراسية ",
      thisWeekMeeting: "إجتماعات هذا الأسبوع",
    },
    report: {
      informations:
        "ستضيف الإدارة النصوص في نهاية كل فصل دراسي حتى تتمكن من مراقبة مستوى طفلك",
      update: "التحديث الاخير: ",
      premsem: "الفصل الأول",
      deuxsem: "الفصل الثاني",
      troisem: " الفصل الثالث ",
      download: "تحميل",
      unavailable: " .بطاقة الاعداد غير متوفرة ",
    },
    canteen: {
      canteen: "مطعم مدرسي",
      informations:
        "يحتاج تلاميذ المدارس والمراهقون إلى نظام غذائي جيد من أجل النمو والتطور والحماية من الأمراض والحصول على الطاقة اللازمة للدراسة والتعلم وأن ينشطوا بدنيًا.",
      informations2: "ستضيف الإدارة أطباق كل يوم",
      choosedate: "اختر التاريخ",
      unavailable: "البيانات غير متوفرة",
      forthisdate: "لهذا التاريخ ",
      schooloffers: " مدرستنا تقدم",
    },
    schedule: {
      informations: "ستضيف الإدارة الجدول الزمني لطفلك لكل فصل دراسي",
      unavailable: "جدول غير متوفر",
    },

    avertissement: {
      teacher: "أستاذ",
      subject: "المادة",
      reason: "المبرر",
      totalnumber: "الرقم الإجمالي",
      notes: "ملاحظات",
      importantInformations: "معلومات مهمة!",
      informations:
        "تساعد العقوبات على عدم استبعاد الفصل بل على الاندماج فيه: فهي تعترف بمسؤولية التلميذ عن أفعاله ، وفي نفس الوقت ، تسمح له بالعودة إلى المجموعة التي استبعد منها نفسه. وهذا ما تؤكده إدارتنا",
      informations2: "لمزيد من المعلومات ، يرجى الاتصال بالادارة",
      unavailable: "لا تحذيرات",
    },
    payment: {
      scolarityPayment: "دفع المدرسة",
      activityPayment: "دفع الأنشطة",
      finalStatus: "الوضع النهائي",
      month: "شهر",
      state: "الحالة",
      activityName: "اسم النشاط",
      fees: "التكاليف",
      progress: "تقدم الدفع",
      singlePayment: "دفع مرة واحدة",
      monthlyPayment: " الدفع الشهري",
      monthlyFees: "رسوم شهرية",
      afterReduction: "بعد التخفيض",
      inscriptionFees: "رسوم التسجيل",
      inscriptionReduction: "تخفيض التسجيل",
      monthlyReduction: "تخفيض شهري",
      monthly: "شهريا",
      inscription: "تسجيل",
    },
    settings: {
      options: "الخيارات",
      child: "الطفل",
      year: "سنة دراسية",
      apply: "تطبيق",
      reset: "إلغاء",
      chooseChild: "اختر من بين أطفالك",
      chooseYear: "اختر العام الدراسي",
    },
    pleaseWait: "... يرجى الإنتظار قليلاً",

    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    arabic: "العربية",
    english: "الإنجليزية",
    french: "الفرنسية",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fr",
  globalInjection: true,
  messages,
});

export default i18n;
